<template>
  <div class="CroppieWrapper">
    <el-popover
      v-model="isEditing"
      placement="bottom"
      trigger="manual"
      popper-class="croppieEditPopover"
    >
      <div
        slot="reference"
        v-loading="isUploading"
        :class="{ 'has-image': hasImage, 'no-image': !hasImage, 'is-circle': isCircle }"
        :style="wrapperComputedStyles"
        class="CroppieWrapper__inner"
        data-testid="dropZone"
        @drop.prevent="_onDrop"
        @dragover.prevent="_onDragover"
        @dragleave.prevent="isDraggedOver = false"
      >
        <vue-croppie
          ref="croppie"
          :enforce-boundary="enforceBoundary"
          :enable-orientation="false"
          :show-zoomer="false"
          :enable-exif="true"
          :enable-resize="false"
          :viewport="settings.viewport"
          :boundary="settings.boundary"
          :min-zoom="0.05"
          result-type="base64"
          @update="_croppieUpdated"
        />

        <div
          v-if="isOverlayVisible"
          class="CroppieWrapper__overlay"
        >
          <label
            v-if="!hasImage"
            :class="{ 'CroppieWrapper__noImage--drag':isDraggedOver, 'has-text-light': isNoImageColorDark, 'has-text-dark': !isNoImageColorDark }"
            :for="identifier"
            :style="noImageColor ? { background: noImageColor }: null"
            class="CroppieWrapper__noImage"
          >
            <slot name="placeholder">
              <span
                :class="`is-size-${textSize}`"
                data-testid="placeholderText"
                class="text"
              >
                <slot>
                  {{ $t('components.croppie_wrapper.upload_thumbnail') }} <br>
                  {{ $t('components.croppie_wrapper.select_file_from_computer') }}
                </slot>

                <span class="icon"> <i class="hf hf-plus" /></span>
              </span>
            </slot>
          </label>

          <div
            v-if="hasImage && !isEditing"
            :class="`is-size-${textSize - 1}`"
            data-testid="actions"
            class="CroppieWrapper__actions"
          >
            <a
              v-if="isEditable"
              class="CroppieWrapper__action CroppieWrapper__editMode m-b-s"
              data-testid="startEdit"
              @click.prevent="_triggerEditMode(true)"
            >
              {{ $t('forms.edit') }}
            </a>
            <a
              class="CroppieWrapper__action CroppieWrapper__removeImage"
              data-testid="removeImage"
              @click.prevent="_removeImage"
            >
              {{ $t('forms.remove') }}
            </a>
          </div>
        </div>

        <input
          :id="identifier"
          ref="file"
          :name="`fileInput_${identifier}`"
          type="file"
          data-testid="fileInput"
          class="CroppieWrapper__input"
          :accept="allowedFormats"
          @change="_onFileChange"
        >
      </div>

      <div class="buttons m-b-none">
        <button
          class="button plain m-b-none"
          type="button"
          data-testid="cancelEdit"
          @click="reset"
        >
          {{ $t('forms.cancel') }}
        </button>
        <button
          class="button is-primary m-b-none"
          type="button"
          data-testid="saveEdit"
          @click="save"
        >
          {{ $t('forms.save') }}
        </button>
      </div>
    </el-popover>
  </div>
</template>

<script>
import fetch from 'cross-fetch'
import _isEqual from 'lodash/isEqual'
import { asyncTimeout } from '../../utils'
import { round } from '../../utils/numberUtils'
import { generateUniqueWatcher } from '../../utils/componentUtilities'
import { croppieDataFactory } from '@hypefactors/shared/js/factories/croppie'
import { VueCroppieComponent } from 'vue-croppie'
import { isColorDark } from '../../utils/colorUtils'

/**
 * A wrapper for VueCroppie that gives much styling and uploading functionality
 * @module CroppieWrapper
 */
export default {
  components: { VueCroppie: VueCroppieComponent },

  props: {
    /**
     * @v-model
     * @type {croppieData}
     */
    value: {
      type: Object,
      required: true,
      default: () => croppieDataFactory()
    },

    /**
     * Used to add an unique identifier to the file input
     * @type {string}
     */
    identifier: {
      type: [String, Number],
      default () { return Date.now() + this._uid }
    },

    /**
     * URL to upload to.
     * @type {String}
     */
    uploadUrl: {
      type: String,
      default: ''
    },

    /**
     * Boundary and Viewport settings
     */
    settings: {
      type: Object,
      required: true
    },

    /**
     * Croppie CropOptions object
     */
    cropOptions: {
      type: Object,
      default: () => ({})
    },

    /**
     * Map object containing scale_name: scale_size
     * @type {Object.<string, number>}
     */
    scaledSizes: {
      type: Object,
      default: null
    },

    /**
     * File size to validate against
     * @type {number}
     */
    fileSize: {
      type: Number,
      default: 10000000
    },

    /**
     * Minimum width of the image
     * @type {number}
     */
    minWidth: {
      type: Number,
      default: 320
    },

    /**
     * Minimum height of the image
     * @type {number}
     */
    minHeight: {
      type: Number,
      default: 180
    },

    /**
     * Is the croppie a circle or not.
     * Mostly visual styling
     * @type {boolean}
     */
    isCircle: {
      type: Boolean,
      default: false
    },

    /**
     * Allows disabling editing after uploading
     */
    isEditable: {
      type: Boolean,
      default: true
    },

    /**
     * The size of the text in the overlays.
     * Uses the is-size-x css helper
     * @type {number|string}
     */
    textSize: {
      type: [Number, String],
      default: 5
    },

    /**
     * Used to set the background color of the noImage container
     * @type {string}
     */
    noImageColor: {
      type: String,
      default: ''
    },

    /**
     * Should croppie enter edit mode on change
     * @type {boolean}
     */
    editOnChange: {
      type: Boolean,
      default: true
    },

    /**
     * The entity you are trying to upload to.
     * @type {String}
     */
    entity: {
      type: String,
      required: true
    },

    /**
     * The brand to upload the image to.
     * Not required everywhere.
     */
    brandId: {
      type: String,
      default: ''
    },

    /**
     * Enforces croppie to forbid zooming out of an image's boundaries
     */
    enforceBoundary: {
      type: Boolean,
      default: true
    },

    noImageSizes: {
      type: Object,
      default: () => ({ width: 0, height: 0 })
    }
  },

  data () {
    return {
      // indicates that the image has been removed. Internally skips uploading if true
      removed: false,
      // indicates a change in croppie (drag,zoom)
      hasChanged: false,
      // new File blob image from uploading/inserting url
      newImage: null,
      // Boolean to show if image is being dragged over before dropping
      isDraggedOver: false,
      // indicates the image is being edited,
      isEditing: false,
      // indicates an upload process
      isUploading: false,
      // keep track of the last croppie update event
      cropInformation: null,
      allowedFormats: 'image/jpeg, image/png'
    }
  },

  computed: {
    /**
     * Checks if there is an image, in croppie, waiting to be uploaded or provided from parent
     * @returns {boolean}
     */
    hasImage () {
      if (this.removed) return false
      return !!this.newImage || !!this.formData.original
    },

    /**
     * Used to get and set the value of the component
     */
    formData: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },

    /**
     * Returns the crop options merged with some default ones.
     * @returns {object} - cropOptions merged with default ones.
     */
    getCropOptions () {
      return Object.assign({ type: 'blob', format: 'png', quality: 1 }, this.cropOptions)
    },

    /**
     * Checks if the overlay should be visible.
     * Shown if there is no image (for label)
     * Show if there is an image and is not editing - to enter edit mode or remove
     * @returns {default.computed.hasImage|boolean}
     */
    isOverlayVisible () {
      return (this.hasImage && !this.isEditing) || !this.hasImage
    },

    isNoImageColorDark () {
      return isColorDark(this.noImageColor)
    },

    wrapperComputedStyles () {
      let height = this.settings.boundary.height
      let width = this.settings.boundary.width

      if (!this.hasImage) {
        height = this.noImageSizes.height || height
        width = this.noImageSizes.width || width
      }

      return {
        height: height + 'px',
        width: width + 'px'
      }
    }
  },

  watch: {
    value: {
      deep: true,
      handler: generateUniqueWatcher('bind', false)
    }
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  mounted () {
    // bind the croppie component on mounted.
    this.bind()
  },

  methods: {

    /**
     * Handles the drop event
     * @param {DragEvent} event
     * @private
     */
    _onDrop (event) {
      this.isDraggedOver = false
      this._onFileChange(event)
    },
    /**
     * Handles file change on input and drop event
     * @param event
     * @private
     */
    _onFileChange (event) {
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      }
      this._processFile(files[0])
    },

    /**
     * Processes the provided file.
     * Validates the image for size and dimensions
     * @param {File} file
     * @returns {Promise<boolean>}
     * @private
     */
    async _processFile (file) {
      try {
        await this._validateImage(file)
        this.newImage = file
        const url = window.URL.createObjectURL(file)

        this.removed = false

        await this.bind({
          url
        })
        this.$emit('file-changed', { url, file })

        if (this.editOnChange) {
          this._triggerEditMode(true)
        }

        return true
      } catch (err) {
        // Image is not valid, we display the error and return false
        this.$notify.error({ message: err.message, duration: 10000 })
        return false
      }
    },

    /**
     * Imports an image url to croppie by transforming to blob
     * @param {string} imgUrl
     * @returns {Promise<any>}
     */
    feedNewImageUrl (imgUrl) {
      return fetch(imgUrl)
        .then(response => response.blob())
        .then(blob => this._processFile(blob)) // simulate file upload
    },

    /**
     * Resets the component's state to a pristine state.
     */
    resetState () {
      this.removed = false
      this.newImage = null
      this._triggerEditMode(false)
      this.hasChanged = false
      this.isDraggedOver = false
      this.cropInformation = null
      this.$refs.file.value = ''
    },

    /**
     * Reset the whole component, state and croppie instance
     */
    reset () {
      this.resetState()
      this.bind()
      this.$emit('cancel')
    },

    /**
     * Removes the currently used image from croppie and the v-model
     * @private
     */
    _removeImage () {
      this.newImage = null
      this.removed = true
      this.formData = croppieDataFactory()
      this.$emit('removed')
      this.$refs.file.value = ''
    },

    /**
     * Handles Croppie's Updated event
     * @param {object} $event
     * @emits croppie-update
     */
    _croppieUpdated ($event) {
      const lastEvent = this.cropInformation
      const currentEvent = this._normalizeEvent($event)

      if (_isEqual(lastEvent, currentEvent)) return

      this.cropInformation = currentEvent
      this._changed(true)
      this.$emit('croppie-update', $event)
    },

    /**
     * Sets the hasChanged property
     * @emits changed
     * @param state
     * @private
     */
    _changed (state = true) {
      this.hasChanged = state
      this.$emit('changed', state)
    },

    /**
     * Gets the crop options for a scaled image
     * @param {number} scale - the save at which to increase by
     * @returns {object} - returns the cropOptions with adjusted sized to the scale
     * @private
     */
    _getScaledOptions (scale) {
      const cropOptions = JSON.parse(JSON.stringify(this.getCropOptions))
      cropOptions.size = {
        width: this.$safeGet(cropOptions, 'size.width', this.settings.boundary.width) * scale,
        height: this.$safeGet(cropOptions, 'size.height', this.settings.boundary.height) * scale
      }
      return cropOptions
    },

    /**
     * Crops and Uploads the image
     * @param {AxiosRequestConfig} [requestOptions]
     * @returns {Promise<unknown> | Promise<boolean>}
     */
    uploadImage (requestOptions) {
      if (this.removed) {
        this.resetState()
        return Promise.resolve(true)
      }
      const newImage = this.newImage
      const hasDoneChanges = this.hasChanged

      if ((!newImage && !hasDoneChanges) || this.isUploading) return Promise.resolve(false)
      this._triggerEditMode(false)
      this.isUploading = true
      return this._cropImages()
        .then(response => this._sendForUpload(response, requestOptions))
        .then(this._handleUploadResponse)
        .catch(e => {
          if (this.$api.isCancelToken(e)) {
            throw e
          }
          this.$handleError(e, { from: 'CroppieWrapper:uploadImage' })
          throw e
        })
        .finally(() => {
          this.isUploading = false
        })
    },

    setIsUploadingStatus (status) {
      this.isUploading = status
    },

    /**
     * Crops the current image and the scaled images
     * @returns {Promise<any[]>}
     * @private
     */
    _cropImages () {
      const newImage = this.newImage

      const fullSizeImageBlobPromise = Promise.resolve(newImage)

      const croppieRef = this.$refs.croppie
      const cropPromise = croppieRef.result(this.getCropOptions)
      let promisesArray = [cropPromise, fullSizeImageBlobPromise]

      if (this.scaledSizes) {
        let scaledPromises = Object.keys(this.scaledSizes).map(sizeName => {
          const scale = this.scaledSizes[sizeName]
          const cropOptions = this._getScaledOptions(scale)

          return croppieRef.result(cropOptions)
        })
        promisesArray = promisesArray.concat(scaledPromises)
      }

      return Promise.all(promisesArray)
    },

    /**
     * Uploads the cropped image, original image and all the scaled images
     * @param {array} results - Array of cropped images
     * @param {AxiosRequestConfig} [requestOptions]
     * @returns {*}
     * @private
     */
    _sendForUpload (results, requestOptions = {}) {
      this.cancelToken && this.cancelToken.cancel()
      this.cancelToken = this.$api.cancelToken()

      const uploadUrl = this.uploadUrl || '/upload/images'

      // Initialize a new form data
      const payload = this._generatePayload(results)

      // Perform the upload
      return this.$api.post(uploadUrl, payload, {
        cancelToken: this.cancelToken.token,
        params: {
          brand: this.brandId || this.activeBrandId
        },
        headers: {
          'Upload-Image-Type': this.entity
        },
        ...requestOptions
      })
    },

    /**
     * Handles the response when uploading
     * Binds the newly uploaded image to croppie.
     * @param response
     * @returns {Promise<boolean>}
     * @private
     */
    async _handleUploadResponse (response) {
      const data = response.data.data

      if (!this.$refs.croppie) {
        throw new Error('Croppie ref is not available during handling of Upload response')
      }

      // Get the original url from the response or default to the old one
      const originalUrl = data.original_url || this.formData.original

      const payload = {
        original: originalUrl,
        cropped: data.cropped_url,
        cropped_information: this._get()
      }

      // If we have scaled images, push to the payload to get saved
      if (data.scaled) {
        payload.scaled = Object.keys(data.scaled).reduce((all, size) => {
          all[size] = {
            scale: this.scaledSizes[size],
            path: data.scaled[size]
          }
          return all
        }, {})
      }

      // Emit updated value in the parent
      this.formData = payload
      this.newImage = null
      this._triggerEditMode(false)

      return true // to indicate successful upload
    },

    _generatePayload (results) {
      const [cropped, originalImage] = results
      const scaledImages = results.slice(2)

      const payloadObject = {}

      // Add virtual form inputs
      // Add the original to the payload if it is
      if (originalImage) {
        payloadObject['original'] = originalImage
      } else {
        // simulate a patch
        payloadObject['_method'] = 'PATCH'
      }

      payloadObject['cropped'] = cropped
      // If we have scaled images add them to the formData
      if (scaledImages.length) {
        const scaledSizes = Object.keys(this.scaledSizes)

        scaledImages.forEach((image, index) => {
          payloadObject['scaled[' + scaledSizes[index] + ']'] = image
        })
      }

      return this._objectToFormData(payloadObject)
    },

    _objectToFormData (object) {
      const formData = new FormData()
      Object.entries(object).map(([key, value]) => {
        formData.append(key, value)
      })
      return formData
    },

    /**
     * Binds the provided image to Croppie.
     * If no data is provided, uses the default formData
     * @param {object} [options] - Croppie bind options
     * @returns {Promise<*>}
     */
    async bind (options) {
      // if the ref is not available, exit
      if (!this.$refs.croppie) return

      if (!options) {
        options = {
          url: this.value.original || '',
          zoom: this.value.cropped_information ? this.value.cropped_information.zoom : 0,
          points: this.value.cropped_information ? this.value.cropped_information.points : ['0', '0', '0', '0']
        }
      }
      options.url = options.url || ''

      if (!options.url) return

      // Refresh because croppie bugs out if trying to bind again.
      await this._refresh()

      // again safety precautions
      if (!this.$refs.croppie) return

      return this.$refs.croppie.bind(options)
        .then((data) => {
          // When done, we set changed to false so its ready
          this._changed(false)
          this.cropInformation = null
          this._triggerEditMode(false)

          return data
        })
        .catch(e => { })
    },

    /**
     * Get the cropped image data from Croppie
     * @returns {croppedInformation}
     * @private
     */
    _get () {
      try {
        return this.$refs.croppie.get()
      } catch (err) {
        console.log(err, 'get')
        return croppieDataFactory().cropped_information
      }
    },

    /**
     * Refresh the croppie instance.
     * @return {Promise<void>}
     * @private
     */
    async _refresh () {
      if (this.$refs.croppie) {
        try {
          this.$refs.croppie.refresh()
        } catch (err) {
          console.log(err, 'refresh')
        }
      }
      return this.$nextTick()
    },

    /**
     * Validates the provided image file for size and dimensions
     * @param file
     * @returns {Promise<any>}
     * @private
     */
    _validateImage (file) {
      return new Promise((resolve, reject) => {
        if (!this.isSupportedFormat(file)) {
          return reject(new Error(this.$t('errors.uploaded_file_not_supported', { formats: 'JPG, PNG' })))
        }
        if (this.fileSize && file.size > this.fileSize) {
          this.$emit('file-size-error', file.size)
          const sizeInMb = this.fileSize / 1000000
          return reject(new Error(this.$t('errors.file_exceeds_permitted_size', { size: sizeInMb })))
        }
        let img = new Image()
        img.onload = () => {
          if (img.naturalHeight < this.minHeight || img.naturalWidth < this.minWidth) {
            return reject(new Error(this.$t('errors.image_too_small')))
          }
          resolve()
        }
        img.src = window.URL.createObjectURL(file)
      })
    },

    /**
     * Sets the isDraggedOver property when dragging image to upload
     * @private
     * @param {DragEvent} e
     */
    _onDragover (e) {
      const files = e.dataTransfer.items || e.dataTransfer.files || []
      if (!this.hasImage && this.isSupportedFormat(files[0])) {
        this.isDraggedOver = true
      }
    },

    save () {
      this._triggerEditMode(false)
      this.$emit('save')
    },

    _normalizeEvent (event) {
      return {
        ...event,
        zoom: round(event.zoom, 3)
      }
    },

    async _triggerEditMode (state) {
      if (state) await asyncTimeout(250)
      this.isEditing = state
      this.$emit('edit', state)
    },

    isSupportedFormat ({ type } = {}) {
      return type && this.allowedFormats.includes(type)
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';
@import '~croppie/croppie';

.CroppieWrapper {
  position: relative;
  display: inline-block;

  &__inner {
    transition: all .15s ease-in;
    display: flex;
    max-width: 100%;
    overflow: hidden;
  }

  &__overlay {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    .CroppieWrapper__noImage {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: lighten($grey-light, 20%);
      border: $grey-light dashed 2px;
      padding: .5rem 1rem;
      margin: 0;
      transition: background .3s;
      cursor: pointer;

      &:hover {
        background: $grey-light;
        border: darken($grey-light, 20%) dashed 2px;
      }

      &--drag {
        border-color: $grey;
      }

      .text {
        text-align: center;
        display: block;

        > .icon {
          display: block;
          padding: 5px;
          margin: auto;
          width: auto;
          height: auto;

          i {
            font-size: 1.5em;
          }
        }
      }
    }

    .CroppieWrapper__actions {
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      @include transit();
      opacity: 0;

      &:hover {
        background: transparentize($dark, .8);
        opacity: 1;
      }
    }

    .CroppieWrapper__action {
      border-radius: 5px;
      background-color: transparentize($dark, .3);
      padding: 0 5px;
      color: $white;

      &:hover {
        background-color: transparentize($dark, .1);
      }
    }
  }

  &__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    opacity: 0;
  }

  .no-image {
    .croppie-container {
      border: none;
    }
  }

  .croppie-container {
    // border: 2px solid $grey-light;
    overflow: hidden;

    .cr-boundary {
      margin: 0;
    }

    .cr-viewport {
      box-shadow: none;
      border: none;
    }

    .cr-slider-wrap {
      margin: 0;
    }

    .cr-image {
      font-size: 0;
    }
  }

  .is-circle {
    position: relative;
    border-radius: 50%;

    .CroppieWrapper__noImage {
      border-radius: 50%;
    }

    .croppie-container {
      border-radius: 50%;

      .cr-boundary {
        border-radius: 50%;
      }
    }
  }
}

.croppieEditPopover {
  padding: .5rem;
  background: $white;
  text-align: center;
}
</style>
